<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <payments-box ref="paymentBox" :purchase="currentSale" @onGenerateInvoice="onGenerateInvoice"/>
            </div>
            <div class="col-md-8">
                <div>
                    <current-purchase :purchase="currentSale"/>
                </div>
                <div>
                    <purchase-detail-list :purchaseToPay="currentSale" @onClickPaymentsButton="onClickPaymentsButton"/>
                </div>
            </div>
        </div>
        <button hidden data-bs-toggle="modal" data-bs-target="#modalPurchasePayments" ref="btnForm"></button>
        <purchase-payments :purchaseId="purchaseId" ref="paymentsForm"/>
        <button hidden data-bs-toggle="modal" data-bs-target="#new-bill-products" ref="btnGenInvoice"/>
        <ModalBillProducts :amountToBill="getAmountToInvoice" :productsInfo="productsToInvoice" />
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, onBeforeMount } from 'vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import PurchaseDetailList from './purchase-detail-list.vue';
import CurrentPurchase from './current-purchase.vue';
import PurchasePayments from './purchase-payments.vue';
import PaymentsBox from "./payments-box.vue";
import { extractParamValue, getBooleanValue } from "../../common/utils";
import ApiService from "@/core/services/ApiService";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import ModalBillProducts from "../billing/products/components/ModalBillProducts.vue";
import { getModule } from "vuex-module-decorators";
import NewBillModule from "@/store/modules/bill/modules/new-bill";

export default defineComponent({
    components: {
        PurchaseDetailList
        , CurrentPurchase
        , PurchasePayments
        , PaymentsBox
        , ModalBillProducts
    }, 
    props: {
        C0: {
            type: String,
            required: true
        }
    },
    setup(props) {
        //VARIABLES Y CONSTANTES
        const controller = "Sales";
        const messageService = new SwalMessageService();
        let selectedView = ref(1);
        let sale = ref(null);
        let btnForm = ref(null);
        let paymentBox = ref(null);
        let paymentsForm = ref(null);
        let saleId = ref("");
        let btnGenInvoice = ref(null);
        let products = ref([]);
        let amountToInvoice = ref(0);
        const moduleForm = getModule(NewBillModule);
        moduleForm.getComboWayToPay();
        moduleForm.getComboUseCfdi();
        moduleForm.getComboPaymentTypes();
        moduleForm.getComboRegimes();

        //HOOKS
        onBeforeMount(() => {
            if(props) {
                saleId.value = extractParamValue(atob(props.C0), "v1");
                if(saleId.value) {
                    ApiService.get(`${controller}/${saleId.value}`).then(res => {
                        if(res.status == 200) {
                            sale.value = res.data;
                        }
                    }).catch(reject => {
                        getMessageError(reject, messageService);
                    });
                }
            }
        });

        onMounted(() => {
            sessionStorage.setItem("payments", "");
            setCurrentPageBreadcrumbs(`Venta`, ["Módulos", { name: "Caja", to: "adminSalesPay"}]);
        });

        //COMPUTEDS
        const currentSale = computed(() => sale.value);
        const purchaseId = computed(() => {
            return sale.value ? sale.value.id : "";
        });
        const getSelectedView = computed(() => selectedView.value);
        const productsToInvoice = computed(() => products.value);
        const getAmountToInvoice = computed(() => amountToInvoice.value);

        //FUNCTIONS
        const onPurchaseFound = (value) => {
            sale.value = value;
            paymentBox.value.resetValues(sale.value.id);
        }

        const onClickPaymentsButton = () => {
            if(btnForm.value) {
                paymentsForm.value.initDefaults();
                btnForm.value.click();
            }
        }

        const onChangeView = (view) => {
            selectedView.value = view;
        }

        const onGenerateInvoice = () => {
            if(sale.value) {
                amountToInvoice.value = sale.value.total;
                products.value = sale.value.saleDetails.map(p => {
                    return { saleDetailId: p.id, productName: p.productName, amount: (p.total + p.tax) };
                });
                moduleForm.SET_BILL_EXTRA_DATA({ customerId: sale.value.customerId, salesId: sale.value.id });
                btnGenInvoice.value.click();
            }
        }

        return {
            //Variables y Constantes
            paymentBox
            , paymentsForm
            , btnForm
            , btnGenInvoice
            , products
            //Computeds
            , getSelectedView
            , currentSale
            , purchaseId
            , productsToInvoice
            , getAmountToInvoice
            //Functions
            , onChangeView
            , onPurchaseFound
            , onClickPaymentsButton
            , onGenerateInvoice
        }
    },
})
</script>

<i18n>
    {
        "es": {
            "TITLE" : "Búsqueda por folio de compra"
        }
        
    }
</i18n>
