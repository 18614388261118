
import { computed, defineComponent, toRefs, ref, watch, onMounted } from "vue";
import FieldsBill from "../../bill/components/FieldsBill.vue";
//import InputNumber from "@/components/forms/InputNumber.vue" 
import * as Validations from "yup";
import { Form } from "vee-validate";
import NewBillModule from "@/store/modules/bill/modules/new-bill";
import { getModule } from "vuex-module-decorators";
import NewBillProducts from "@/models/bill/NewBillProducts";
import BasicFieldsBill from "@/models/bill/BasicFieldsBill";
import { RfcFormat } from "@/core/shared/ValidationRegex";
import { hideModalById } from "@/core/helpers/dom";
import SwalMessageService from "@/core/services/SwalMessageService";
import { useRoute, useRouter } from "vue-router";
import { SwalIconType } from "@/core/enums/swal-icon-type";
import { RouteNames } from "@/router/route-names";

export default defineComponent({
    components: {
        FieldsBill
        , Form
    },
    props: {
        amountToBill: {
            type: Number
            , required: true
            , default: 0
        },
        /*SalesDetailsIds que es uno por producto */
        saleDetailIds: {
            type: Array
            , default: () => []
        },
        callFromBillModule: {
            type: Boolean
            , default: false
        }
    },
    setup(props){
        const moduleForm = getModule(NewBillModule);
        const router = useRouter()
        const { amountToBill, saleDetailIds, callFromBillModule } = toRefs(props);
        const form = ref();
        const validationSchema = computed(() => Validations.object().shape({
                name: Validations.string().default('').required()
                , businessName: Validations.string().default('').required()
                , rfc: Validations.string().required().matches(RfcFormat, { excludeEmptyString: true, message: 'Formato icorrecto de RFC' })
                , useCfdiId: Validations.string().default('').required()
                , paymentMethodId: Validations.string().default('').required()
                , wayToPayId: Validations.string().default('').required()
                , zipCode: Validations.string().default('').required()
                , email: Validations.string().email().required()
                , regimeId: Validations.string().default('').required()
            }));
        const messageService = new SwalMessageService();

        const saveBill = (values: BasicFieldsBill, {resetForm}) =>{
            //guarda la factura
            const bill = {
                ...values,
                saleDetailIds: saleDetailIds.value
            } as NewBillProducts;

            moduleForm.SAVE_BILL_PRODUCTS({ form: bill, reloadTableProducts: callFromBillModule.value })
            .then((isSuccess) => {
                //ajustar
                if(isSuccess){
                    resetForm();
                    //ocultar modal
                    hideModalById('#new-bill-products')
                    //mensaje de exito
                    //mostrar la opcion para abrir la factura en el modulo de creadas
                    messageService.confirm(SwalIconType.Warning,'¿Desea ir a ver la facturas creadas para poder descargar sus archivos?')
                    .then(isYes => {
                        //enviar mediante el route a la pagina facturas creadas mediante el numBill
                        router.push({
                            query: {
                                numBill: ''//response.BillFolio
                            }
                            , name: RouteNames.BillCreated
                        })
                    })
                }
            }).finally(() => {
                messageService.success("La factura se generó de manera exitosa.");
            });
            //llamar al metodo de vuex y reset form
        }
        
        onMounted(() => {
            /*emitter.on('resetBillForm', () => {
                bills.value = [{
                    amount: 0
                    , positionNumber: 1
                    , saleId: ''
                    , saleFolio: ''
                }]
                form.value.resetForm();
                hideModalById('#new-bill-products');//TODO posiblemente se quite
            })*/
        })

        watch(() => moduleForm.preselectData, (newValues) => {
            form.value.setValues({
                useCfdiId: newValues.typeUseCFDIId
                , paymentMethodId: newValues.paymentMethodId
                , wayToPayId: newValues.wayToPayId
                , regimeId: newValues.regimeId
            })
        })
        return {
            saveBill
            , validationSchema
            , form
        }
    }
})
