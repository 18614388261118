import Actions from './Actions';
import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Identifiable } from "@/models/general/Identifiable";
import { Mutations } from './Mutations';
import { realoadTable } from '@/store/functions/GenericTable';
import NewSaleState from '../../models/NewSaleState';
import NewSale from '@/models/sales/NewSale';
import SaleService from '@/core/services/sales/SaleService';
import SaleDetail from '@/models/sales/SaleDetail';
import OptionSelectClient from '@/models/sales/clientsSale';
import InfoSale from '@/models/sales/InfoSale';
import SenToSale from '@/models/sales/SenToSale';
import SenToCash from '@/models/sales/SenToCash';
import NewSaleDetail from '@/models/sales/NewSaleDetail';
import UpdateSaleDetail from '@/models/sales/UpdateSaleDetail';
import CancelSale from '@/models/sales/CancelSale';


@Module({dynamic: true, store, namespaced: true, name: Modules.NewSaleModule})
export default class NewSaleModule extends VuexModule implements NewSaleState {
    idSelected = '';
    idClientSelected = '';
    taxt = 0;
    selects = {
        clients: [] as OptionSelectClient[]
    }
    pdf = "";
    /**
     * Los valores iniciales son los valores del formulario, se usan para las ediciones
     */
    initialValues = formDefaultValues
    loading = false;
    loadingClients = false;
    initialSelectProduct = detailDefaultValue;
    initialSaleDetail = defaultSaleDetail;

    get getbase64PDF(){
        return  "data:application/pdf;base64," + this.pdf;
    }


    get getClientOptions(): OptionSelectClient[] {
        return this.selects.clients;
    }

    get getSaleDetail(): SaleDetail[] {
        return this.initialSaleDetail?.filter(x => x.status == true);
    }

    get getSaleDetailAll(): SaleDetail[] {
        return this.initialSaleDetail;
    }

    get getSalectProduct(): SaleDetail{
        return this.initialSelectProduct;
    }

    get getValuesForm(): InfoSale {
        return this.initialValues;
    }
    /**
     * Indica si existe un identificador de un cliente a modificar
     */
    get IsUpdateForm() : boolean {
        return this.idSelected != '';
    }

    get getImportSale() : number {
        return this.initialSaleDetail == undefined ? 
                    0 : 
                    this.initialSaleDetail?.filter(x => x.status == true).reduce((sum, current) => sum + current.subTotal, 0);
    }

    get getDiscontSale() : number {
        return this.initialSaleDetail == undefined ? 
                    0 : 
                    this.initialSaleDetail?.filter(x => x.status == true).reduce((sum, current) => sum + current.discount, 0);
    }

    get getTaxSale() : number {
        return this.initialSaleDetail == undefined ? 
                    0 : 
                    this.initialSaleDetail?.filter(x => x.status == true).reduce((sum, current) => sum + current.tax, 0) //* iva
    }

    get getTotalSale() : number {
        return this.initialSaleDetail == undefined ? 
                    0 : 
                    this.getImportSale + this.getTaxSale//+ iva
    }

    @Mutation
    [Mutations.SAVE_TAX_VALUE](tax: number){
        this.taxt = tax;
    }

    @Mutation
    [Mutations.SAVE_CLIENT_SELECTED](id: string){
        this.idClientSelected = id;
    }

    @Mutation
    [Mutations.SET_QUOTEVIE](data: string){
        this.pdf = data;
    }

    @Mutation
    [Mutations.SET_QUOTEVIE_COMPLETE](data: string){
        this.pdf = data;
    }

    @Mutation
    [Mutations.SET_CLIENTS](value: OptionSelectClient[]){
        this.selects.clients = value;
    }

    @Mutation
    [Mutations.SAVE_EDIT_PRODUCT](value: SaleDetail){
        this.initialSelectProduct = value;
    }

    @Mutation
    [Mutations.UPDATE_EDIT_PRODUCT](value: SaleDetail){
        if(this.initialSaleDetail == undefined){
            this.initialSaleDetail = [value];
        }else{
            this.initialSaleDetail.push(value);
        }
    }

    @Mutation
    [Mutations.RESET_EDIT_PRODUCT](){
        this.initialSelectProduct = detailDefaultValue;
    }

    @Mutation
    [Mutations.ADD_PRODUCT](value: SaleDetail){
        if(this.initialSaleDetail == undefined){
            this.initialSaleDetail = [value];
        }else{
            this.initialSaleDetail.push(value);
        }
    }

    @Mutation
    [Mutations.DELETE_PRODUCT](id: string){
        const index = this.initialSaleDetail.findIndex(d => d.productId == id);
        
        if (index > -1) {
            this.initialSaleDetail.splice(index, 1);
        }
    }

    @Mutation
    [Mutations.RESET_VALUES_FORM](){
        this.initialValues = formDefaultValues;
        this.initialSaleDetail = [] as SaleDetail[];
        this.idSelected = '';
    }

    @Mutation
    [Mutations.SET_DATA_SALE](values: Identifiable<string, InfoSale>){
        if(values && (values.id ?? '') != ''){
            const data = values as InfoSale;
            this.initialValues = data;
            this.initialSaleDetail = data.saleDetails;
            this.idSelected = values.id;
        }
    }

    @Mutation
    [Mutations.SET_INITIAL_DATA_SALE](values: Identifiable<string, InfoSale>){
            const data = values as InfoSale;
            this.initialValues = data;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }

    @Mutation
    [Mutations.SET_LOADING_CLIENTS](value: boolean){
        this.loadingClients = value;
    }

    @Mutation
    [Mutations.SET_LOADINGS](values: any){
        this.loading = values;
    }

    @Action
    async [Actions.SAVE_NEW_SALE](form: NewSale<NewSaleDetail>){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.saveSale(form)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.SaleTableModule)
                } 
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
                this.context.commit(Mutations.RESET_VALUES_FORM)
            })
    }
    
    @Action
    async [Actions.UPDATE_SALE](newData: NewSale<UpdateSaleDetail>){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.updateSale(newData)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.SaleTableModule)
                } 
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
                // this.context.commit(Mutations.RESET_VALUES_FORM)
            });
    }

    @Action
    async [Actions.ACTIVE_SALE](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.activeSale(id)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.SaleTableModule)
                } 
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            });
    }

    @Action
    async [Actions.DISABLE_SALE](data: CancelSale){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.disableSale(data)
            .then((resp) => {
                realoadTable(this.context, Modules.SaleTableModule)
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            });
    }

    @Action
    async [Actions.DISABLE_SALE_COTIZACION](data: CancelSale){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.disableSale(data)
            .then((resp) => {
                realoadTable(this.context, Modules.SaleTableQuoteModule)
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            });
    }

    @Action
    async [Actions.SEN_TO_PAY](data: SenToCash){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.senToPaySale(data)
            .then((resp) => {
                realoadTable(this.context, Modules.SaleTableModule)
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            });
    }

    @Action
    async [Actions.SEN_TO_SALE](sale: SenToSale){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.senToSale(sale)
            .then((resp) => {
                realoadTable(this.context, Modules.SaleTableQuoteModule)
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            });
    }

    @Action
    async [Actions.SEN_TO_SCHEDULE](sale: SenToCash){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.senToSchedule(sale)
            .then((resp) => {
                realoadTable(this.context, Modules.SaleTableModule)
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            });
    }

    @Action({ commit: Mutations.SET_DATA_SALE })
    async [Actions.SEARCH_INFO_SALE](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.searchInfo(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? formDefaultValues
    }

    @Action({ commit: Mutations.SET_CLIENTS })
    async [Actions.SEARCH_CLIENTS](filterName: string){
        this.context.commit(Mutations.SET_LOADING_CLIENTS, true);
        return (await service.searchClientOptions(filterName)
            .finally( () => this.context.commit(Mutations.SET_LOADING_CLIENTS, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_QUOTEVIE })
    async [Actions.GET_QUOTEVIE](id: string){
        this.context.commit(Mutations.SET_LOADING_CLIENTS, true);
        return (await service.GetQuotesView(id)
            .finally( () => this.context.commit(Mutations.SET_LOADING_CLIENTS, false))).data ?? false
    }

    @Action({ commit: Mutations.SET_QUOTEVIE_COMPLETE })
    async [Actions.GET_QUOTEVIE_COMPLETE](id: string){
        this.context.commit(Mutations.SET_LOADING_CLIENTS, true);
        return (await service.GetQuotesCompleted(id)
            .finally( () => this.context.commit(Mutations.SET_LOADING_CLIENTS, false))).data ?? false
    }

    @Action({ commit: Mutations.SET_QUOTEVIE_COMPLETE })
    async [Actions.GET_RECEIP_VIEW](id: string){
        this.context.commit(Mutations.SET_LOADING_CLIENTS, true);
        return (await service.GetReceiptView(id)
            .finally( () => this.context.commit(Mutations.SET_LOADING_CLIENTS, false))).data ?? false
    }

    @Action
    async [Actions.ADD_PRODUCT](newProduct: SaleDetail){
        this.context.commit(Mutations.ADD_PRODUCT, newProduct);
    }

    @Action
    async [Actions.DELETE_SAVE_PRODUCT](id: string){
        this.context.commit(Mutations.DELETE_PRODUCT, id);
    }

    @Action
    async [Actions.UPDATE_PRODUCT](updateProduct: SaleDetail){
        this.context.commit(Mutations.UPDATE_EDIT_PRODUCT, updateProduct);
    }

    @Action
    async [Actions.SAVE_PRODUCT](product: SaleDetail){
        this.context.commit(Mutations.SAVE_EDIT_PRODUCT, product);
    }

    @Action
    async [Actions.RESET_PRODUCT](){
        this.context.commit(Mutations.RESET_EDIT_PRODUCT);
    }

    @Action
    async [Actions.RESET_NEW_SALE](){
        this.context.commit(Mutations.RESET_VALUES_FORM);
    }


}
const service =  new SaleService();

/**
 * Valores por defecto del formulario
 */
 const formDefaultValues = {
    id: "",
    customerId: "",
    customer: "",
    invoince: "",
    isBilled: false,
    observation: "",
    receiver: "",
    addressId: "",
    address: "",
    agent: "",
    createdAt: "",
    total: 0,
    subTotal: 0,
    discount: 0,
    tax: 0,
    saleTypeId: "",
    saleTypeName: "",
    saleDetails: []
} as InfoSale;

const detailDefaultValue = {
    saleId: "",
    productId: "",
    code: "",
    amount: 0,
    discount: 0,
    discountPersent: 0,
    price: 0,
    tax: 0,
    taxPersent: 0,
    total: 0,
    unitOfMeasurementId: ""
    
} as SaleDetail;

const defaultSaleDetail = [] as SaleDetail[];

