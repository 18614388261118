import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ffd0f252"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-stack pt-15" }
const _hoisted_2 = {
  type: "submit",
  class: "btn btn-lg btn-primary me-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldsBill = _resolveComponent("FieldsBill")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_Form, {
      "validation-schema": _ctx.validationSchema,
      onSubmit: _ctx.saveBill,
      class: "mx-auto w-100 pt-15 pb-10",
      ref: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FieldsBill),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.$t("BUTTONS.SUBMIT")), 1)
        ])
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"])
  ]))
}