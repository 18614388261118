import NewBillRequest from '@/models/bill/NewBillRequest';
import Modules from "@/store/enums/Modules";
import { Action, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store"
import NewBillState from "../../models/NewBillState";
import OptionSelect from "@/models/shared/OptionSelect";
import NewBill from "@/models/bill/NewBill";
import BillService from "@/core/services/bill/BillService";
import emitter from "@/core/emitters";
import ComboSelectService from "@/core/services/general/ComboSelectService";
import WayToPayService from "@/core/services/wayToPay/WayToPayService";
import TypeCFDIService from "@/core/services/typeCfdi/TypeCFDIService";
import PaymentTypeService from "@/core/services/paymentType/PaymentTypeService";
import RegimeService from "@/core/services/regime/RegimeService";
import BillInfoExtra from "@/models/bill/BillInfoExtra";
import NewBillProducts from "@/models/bill/NewBillProducts";
import * as Table from '@/store/commons/MutationsTable';

enum Mutations {
    SET_USE_CFDI_OPTIONS = 'SET_USE_CFDI_OPTIONS'
    , SET_VALUE_LOADING = 'SET_VALUE_LOADING'
    , RESET_PRESELECT_DATA = 'RESET_PRESELECT_DATA'
}

enum Actions {
    SAVE_BILL = 'SAVE_BILL'
    , SET_BILL_EXTRA_DATA = 'SET_BILL_EXTRA_DATA'
    , SAVE_BILL_PRODUCTS = 'SAVE_BILL_PRODUCTS'
}

@Module({ dynamic: true, store, namespaced: true, name: Modules.NewBill})
export default class NewBillModule extends VuexModule implements NewBillState{
    preselectData = { 
        salesId: '' 
        , customerId: '' 
    } as BillInfoExtra;
    selects = { 
        cfdiOptions: [] as OptionSelect[] 
        , wayToPayOptions: [] as OptionSelect[]
        , paymentMethodsOptions: [] as OptionSelect[]
        , regimeOptions: [] as OptionSelect[]
    };
    loading = false;
    
     /**
     * Opciones de cfdi
     * @returns Array<OptionSelect>
     */
     get getuseCfdiOptions(): Array<OptionSelect> {
        return this.selects.cfdiOptions;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_USE_CFDI_OPTIONS](options: OptionSelect[]){
        this.selects.cfdiOptions = options;
    }
    @Mutation
    [Mutations.RESET_PRESELECT_DATA](){
        this.preselectData = {
            customerId: ''
            , salesId: ''
        }
    }

    @Action
    async [Actions.SAVE_BILL](form: NewBill){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);//indicamos que esta cargando (TODO debe ser solo el formulario)
        return service.saveBill(form)
        .then((resp) => {
            if(resp.data){
                // * clean table sales
                // * show modal with xml or pdf to download 
                //(debe ser una tabla con las opciones a descargar de cada venta (mejor dicho cada factura de la venta por que puede dividirse))
                //emitter.emit('resetBillForm');
            } 
            
            return resp.data;
        })
        .finally( () => {
            this.context.commit(Mutations.SET_VALUE_LOADING, false) 
        });
        
    }
    @Action
    async [Actions.SAVE_BILL_PRODUCTS]({ form, reloadTableProducts } : { form: NewBillProducts, reloadTableProducts: boolean }){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);//indicamos que esta cargando (TODO debe ser solo el formulario)

        const request = {
            cp: form.zipCode
            , customerId: this.preselectData.customerId
            , invoiceStatus: 0//por defecto
            , nameReciver: form.name
            , paymentMethodId: form.paymentMethodId
            , regimeId: form.regimeId
            , rfc: form.rfc
            , rfcReciver: form.rfc//por defecto el mismo... puede cambiar despues
            , saledetailsIds: form.saleDetailIds
            , salesId: this.preselectData.salesId
            , typeUseCFDIId: form.useCfdiId
            , wayToPayId: form.wayToPayId
        } as NewBillRequest

        return service.saveBillByProducts(request)
        .then((resp) => {
            let isSuccess = false;
            if(resp.data && resp.data.id.length > 0){
                isSuccess = true;
                if(reloadTableProducts){
                    // * clean table products bill
                    this.context.commit(Modules.TableBillProducts + '/' + Table.Mutations.RESET_TABLE, {}, { root: true });
                    this.context.commit(Mutations.RESET_PRESELECT_DATA);
                }
                
                // * show modal with xml or pdf to download 
                emitter.emit('showBillCreated', { billId: resp.data.id, fileName: 'FileName'});
            } 
            
            return isSuccess;
        })
        .finally( () => {
            this.context.commit(Mutations.SET_VALUE_LOADING, false) 
        });
        
    }

    @Action
    async DownloadBillFile(billId: string){
        return service.getBillFile(billId).then(resp => resp?.data ?? '');

    }

     /**RECUPERACION DE COMBOS */
     @MutationAction
     async getComboWayToPay(){
         const ways = await serviceWayToPay.searchCombo('');
         const selects = { ...this.selects }
         return { selects: { ...selects, wayToPayOptions: ways.data ?? [] }}
     }
     @MutationAction
     async getComboUseCfdi(){
         const cfdis = await serviceTypeCFDIService.searchCombo('');
         const selects = { ...this.selects }
         return { selects: { ...selects, cfdiOptions: cfdis.data ?? [] }}
     }
     @MutationAction
     async getComboPaymentTypes(){
         const paymentTypes = await servicePaymentTypeService.searchCombo('');
         const selects = { ...this.selects }
         return { selects: { ...selects, paymentMethodsOptions: paymentTypes.data ?? [] }}
     }
     @MutationAction
     async getComboRegimes(){
         const regimes = await serviceRegime.searchCombo('');
         const selects = { ...this.selects }
         return { selects: { ...selects, regimeOptions: regimes.data ?? [] }}
     }
     @MutationAction
     async [Actions.SET_BILL_EXTRA_DATA](extraData: BillInfoExtra){
         return { preselectData: { ...this.preselectData, ...extraData }}
     }
}

const service =  new BillService();
const serviceWayToPay =  new WayToPayService() as ComboSelectService<OptionSelect>;
const serviceTypeCFDIService =  new TypeCFDIService() as ComboSelectService<OptionSelect>;
const servicePaymentTypeService=  new PaymentTypeService() as ComboSelectService<OptionSelect>;
const serviceRegime = new RegimeService() as ComboSelectService<OptionSelect>; 

export { Actions }