export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLES": {
          "BILL_PRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Facturación Por Productos"])}
        },
        "SUB_TITLE_BILL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El monto completo por todos los productos seleccionados es de ", _interpolate(_named("amount")), "."])}
      }
    }
  })
}
