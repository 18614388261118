
import { defineComponent, computed, toRefs, PropType} from "vue";
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue";
import BillProductsForm from './BillProductsForm.vue';

import {formatNumeric} from "@/core/shared/functions/Formatter"
import BillProductInfo from "@/models/bill/BillProductInfo";
export default defineComponent({
    components:{
        DynamicContentModal
        , BillProductsForm
    },
    props:{
        amountToBill: {
            type: Number
            , required: true
            , default: 0
        },
        //información de productos a facturar en un solo monto 
        productsInfo: {
            type: Array as PropType<Array<BillProductInfo>>
            , default: () => []
        }
    },
    setup(props) {
        //
        const { productsInfo } = toRefs(props);

        const saleDetailIds = computed(() => productsInfo.value.map(m => m.saleDetailId))
        const cleanForm = () => {
            //emitter.emit("resetBillForm"); se llama cuando s eguarda el reguistro
        }
        return {
            cleanForm
            , formatNumeric
            , saleDetailIds
        }
    },
})
